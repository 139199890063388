<template>
  <el-main>
    <form-layout ref="formLayout" :dataForm="form" :group="group"></form-layout>
    <userDialog
      ref="userDialog"
      :userFrom="form"
      :type="roleConfigBatchDialogType"
    ></userDialog>
  </el-main>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import {selectChildren} from "@/api/system/dept";
import HeadLayout from "@/views/components/layout/head-layout"
import UploadLayout from "@/views/components/layout/upload-layout"
import website from '@/config/website';
import {mapGetters} from "vuex";
import userDialog from "@/views/system/userDialog";

let deptListInput = {}
export default {
  name: "tab-form",
  components: {FormLayout, HeadLayout, UploadLayout, userDialog},
  props: {
    roleConfigRow: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      roleConfigBatchDialogType: "roleConfigBatchDialog",
      classCodeList: '',
      deptDicData: [],
      form: {},
      initFlag: true,
      treeList: [],
      deptList: [],
      observer: null
    };
  },
  computed: {
    ...mapGetters(['language']),
    group() {
      return [
        {
          column: [
            {
              label: this.$t("cip.plat.sys.user.field.selectDept"),
              prop: "deptId",
              span: 8,
              type: "tree",
              multiple: true,
              dicData: this.deptDicData,
              props: {
                label: "title"
              },
              checkStrictly: true,
              slot: true,
              change(row) {
                deptListInput = row
              },
            },
          ]
        },
      ]
    }
  },
  watch: {},
  mounted() {
    this.initData(website.tenantId);
    this.$refs.userDialog.getDataList();
    // if (this.type == "roleConfigBatchDialog") {
    //   this.roleConfiguration()
    // }else {
    this.listenerDom()
    // }
  },
  destroyed() {
    // if (this.type != "roleConfigBatchDialog") {
    this.observer.disconnect();
    // }

  },
  methods: {
    listenerDom() {
      // 目标DOM元素
      const targetElement = document.getElementsByClassName(
        "el-input el-input--small el-input--suffix"
      );
      // 创建观察器对象
      this.observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "attributes") {
            // 只处理属性变化
            if (mutation.target.className.indexOf("is-focus") == -1) {
              this.roleConfiguration()
            }
          }
        }
      });
      // 配置观察选项
      const config = {attributes: true};
      // 开始观察目标元素
      this.observer.observe(targetElement[0], config);
    },
    roleConfiguration() {
      this.deptList = []
      if (this.type == "roleConfigBatchDialog") {
        //构造方法进行回显
        this.treeList =this.roleConfigRow.roleIds
        this.form.deptId = this.roleConfigRow.deptId
      } else {
        this.treeList = deptListInput.value;
      }
      const column = this.findObject(this.group, "deptId");
      var item = this.deptDicData;
      // this.roleConfigRow
      this.treeList.forEach(e => {
        //查询上级部门
        let findParentNode = this.findParentNode(column.dicData, e.deptId);
        //查询本级部门
        let findChildData = this.findChildData(item, e.deptId);
        if (findParentNode == null) {
          let findParentNode1 = {}
          findParentNode1.departmentId = findChildData.id
          findParentNode1.department = findChildData.title
          findParentNode1.deptAncestor = findChildData.organizeIds
          findParentNode1.superiorDepartment = findChildData.title
          findParentNode1.superiorDepartmentId = findChildData.id
          findParentNode1.isDefaultDept = e.isDefaultDept
          findParentNode1.roleId = e.roleId.split(",");
          findParentNode1.roleName = e.roleName.split(",");
          this.deptList.push(findParentNode1);
        } else {
          let find = {}
          find.departmentId = findChildData.id
          find.department = findChildData.title
          find.deptAncestor = findChildData.organizeIds
          find.superiorDepartment = findParentNode.title
          find.superiorDepartmentId = findParentNode.id
          find.isDefaultDept = e.isDefaultDept
          find.roleId = e.roleId.split(",");
          find.roleName = e.roleName.split(",");
          this.deptList.push(find);
        }
        //查询出所有的部门
      })
      this.$refs.userDialog.deptList = this.deptList;
      if (this.type == "roleConfigBatchDialog") {
        this.$refs.userDialog.getDataList();
      } else
        this.$refs.userDialog.getDeptList();

    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    findParentNode(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          // 判断当前节点的子节点是否包含目标子节点
          const isChild = node.children.some(child => child.id === childId);
          if (isChild) {
            // 如果存在目标子节点，返回当前节点
            return node;
          } else {
            // 否则递归查找当前节点的子节点
            const parentNode = this.findParentNode(node.children, childId);
            if (parentNode) {
              // 如果找到了目标子节点的上一级节点，返回该节点
              return parentNode;
            }
          }
        }
      }
      // 没有找到目标子节点的上一级节点，返回 null
      return null;
    },
    submit() {
      return this.$refs.userDialog.submit();
    },
    initData(tenantId) {
      if (tenantId == "roleConfigBatchDialog") {
        selectChildren().then(res => {
          const column = this.findObject(this.group, "deptId");
          this.deptDicData = res.data.data;
          column.dicData = res.data.data;
          this.roleConfiguration()
        });
      } else {
        selectChildren().then(res => {
          const column = this.findObject(this.group, "deptId");
          this.deptDicData = res.data.data;
          column.dicData = res.data.data;
        });
      }
    },
    handleBack() {
    },

  }
}
</script>

<style scoped>
</style>
