<template>
  <div>
    <el-container>
      <div>
        <el-row style="padding: 14px 12px;background: #FFFFFF;position: relative">
          <el-input size="small" :placeholder="$t('cip.cmn.msg.warning.filterateWarning')" v-model="filterText"
            class="searchInput" :class="popoverShow ? 'inputWidth' : ''">
          </el-input>
        </el-row>
        <el-tree ref="commonTree" v-loading="loading" :data="treeData" :props="defaultProps" :default-expand-all="true"
          :highlight-current="true" :expand-on-click-node="false" :check-strictly="true" :show-checkbox="true"
          node-key="id" @node-click="nodeClick" @check-change="handleCheckChange" :filter-node-method="filterNode"
          style="font-size: 12px;border-radius: 4px;padding:0 5px 0 12px">
          <span slot-scope="{ node, data }" style="display: flex">

            <i v-if="data.children" class="el-icon-folder" style="line-height: 28px"></i>
            <i v-else-if="data.category == 1" class="el-icon-folder" style="line-height: 28px"></i>
            <i v-else-if="data.category == 'all'" class="el-icon-star-off" style="line-height: 28px"></i>
            <i v-else class="el-icon-document" style="line-height: 28px"></i>

            <el-tooltip :content="node.label" placement="bottom">
              <span
                style="margin-left: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; width: 200px;display: block">{{
            node.label }}</span>
            </el-tooltip>
          </span>
        </el-tree>
      </div>
      <el-container>
        <el-main>
          <head-layout :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')">
          </head-layout>
          <grid-head-layout ref="searchFrom" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns" v-model="searchForm" @grid-head-search="searchChange"
            @grid-head-empty="searchReset">
          </grid-head-layout>
          <grid-layout ref="gridLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :data-total="page.total" @page-size-change="onLoad" @page-current-change="onLoad"
            @page-refresh-change="onLoad" :page="page" @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
            :searchColumns="searchColumns">
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
    <div style="display: flex;justify-content: center">
      <el-button size="medium" type="primary" @click="confirm" style="margin-right: 24px">确认</el-button>
      <el-button size="medium" @click="closeDialog">取消</el-button>
    </div>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getSysConfig, getUserInfo, grantMultiDept } from "@/api/system/user";
import {
  getList,
  getUser,
  getUserPlatform,
  remove,
  update,
  updatePlatform,
  add,
  grant,
  resetPassword, unlock, enable, disable
} from "@/api/system/user";
import roleConfigBatchDialog from "@/views/gradingManager/roleConfigBatchDialog";
import {
  remove as delDept,
  getList as deptList,
  getDeptTree, getLazyListDept, getDeptLazyTreeAll
} from "@/api/system/dept";
import { exportBlob } from "@/api/common";
import { treeByUserId } from "@/api/system/dept";
import { getRoleTree } from "@/api/system/role";
import { getPostList } from "@/api/system/post";
import { mapGetters } from "vuex";
import website from '@/config/website';
import { getToken } from '@/util/auth';
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
    roleConfigBatchDialog
  },
  props: {
    deptCategory: [],
    userDeptType: {
      type: String,
      default: ""
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("cip.plat.sys.user.field.inputPassword")));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("cip.plat.sys.user.field.checkPassword")));
      } else if (value !== this.$refs.formLayout.$refs.form.form.password) {
        callback(new Error(this.$t("cip.plat.sys.user.field.uncheckPassword")));
      } else {
        callback();
      }
    };
    return {
      filterText: '',
      treeData: [],
      treeCheckData: [],
      defaultProps: {
        label: "title",
        value: "id"
      },
      // treeOptions: {
      //   defaultExpandAll: false,
      //   nodeKey: 'id',
      //   lazy: true,
      //   treeLoad: function (node, resolve) {
      //     const parentId = (node.level === 0) ? 0 : node.data.id;
      //     getDeptLazyTreeAll(parentId).then(res => {
      //       resolve(res.data.data.map(item => {
      //         return {
      //           ...item,
      //           leaf: !item.hasChildren
      //         }
      //       }))
      //     });
      //   },
      //   menu: false,
      //   addBtn: false,
      //   // size: 'mini',
      //   props: {
      //     labelText: this.$t("cip.plat.sys.user.field.title"),
      //     label: 'title',
      //     value: 'value',
      //     children: 'children'
      //   }
      // },
      // roleConfigBatchDialog: false,
      // roleConfigRow: {},
      // type: "roleConfigBatchDialog",
      // deptList: [],
      // searchTitle: 'title',

      // tenantStatus: "",
      searchForm: {},
      // echoMultipleData: [],
      // gridRowBtn: [
      //   {
      //     label: this.$t('cip.cmn.btn.defBtn'),
      //     emit: "role-defBtn",
      //     type: "text",
      //     icon: ""
      //   }
      // ],
      // dataTotal: null,

      tableData: [],
      tableLoading: true,
      // form: {},
      // search: {},
      // roleBox: false,
      // excelBox: false,
      // initFlag: true,
      selectionList: [],
      query: {},
      // loading: true,
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      // init: {
      //   roleTree: [],
      //   deptTree: [],
      // },
      // props: {
      //   label: "title",
      //   value: "key"
      // },
      // roleGrantList: [],
      // roleTreeObj: [],
      // treeDeptId: '',
      tableOptions: {
        customRowAddBtn: false,
        menu: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            cell: true,
            hide: '',
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "returnToDepartment",
            cell: true,
            align: 'left',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: 'center',
            dataType: "number",
            prop: "userType",
            hide: true,
            width: 150,
            overHidden: true,
          },
        ],
        group: [
          {
            label: this.$t("cip.plat.sys.user.field.baseInfo"),
            prop: 'baseInfo',
            icon: 'el-icon-user-solid',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.tenantName"),
                prop: "tenantId",
                type: "tree",
                dicUrl: "/api/sinoma-system/tenant/select",
                props: {
                  label: "tenantName",
                  value: "tenantId"
                },
                hide: !website.tenantMode,
                addDisplay: website.tenantMode,
                editDisplay: website.tenantMode,
                viewDisplay: website.tenantMode,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.tenantName"),
                  trigger: "click"
                }],
                span: 24,
              },
              // {
              //   label: this.$t("cip.plat.sys.user.field.account"),
              //   prop: "account",
              //   rules: [{
              //     required: true,
              //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
              //     trigger: "blur"
              //   }],
              // },
              {
                label: this.$t("cip.plat.sys.user.field.userType"),
                type: "select",
                dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey"
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.userType"),
                  trigger: "blur"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.password"),
                prop: 'password',
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{ required: true, validator: validatePass, trigger: 'blur' }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.password2"),
                prop: 'password2',
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{ required: true, validator: validatePass2, trigger: 'blur' }]
              },
            ]
          },
          {
            label: this.$t("cip.plat.sys.user.field.detailInfo"),
            prop: 'detailInfo',
            icon: 'el-icon-s-order',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.name"),
                prop: "name",
                hide: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.name"),
                  trigger: "blur"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.realName"),
                prop: "realName",
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
                  trigger: "blur"
                }, {
                  min: 2,
                  max: 5,
                  message: this.$t("cip.plat.sys.user.field.nameLength"),
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.phone"),
                prop: "phone",
                overHidden: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.email"),
                prop: "email",
                hide: true,
                overHidden: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.sex"),
                prop: "sex",
                type: "select",
                dataType: 'number',
                props: {
                  label: "dictValue",
                  value: "dictKey"
                },
                dicUrl: "/api/sinoma-system/dict/dictionary?code=gender",
                hide: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.birthday"),
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd hh:mm:ss",
                valueFormat: "yyyy-MM-dd hh:mm:ss",
                hide: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.statusName"),
                prop: "statusName",
                hide: true,
                display: false
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.user.field.dutyInfo"),
            prop: 'dutyInfo',
            icon: 'el-icon-s-custom',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.code"),
                prop: "code",
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicData: [],
                props: {
                  label: "title"
                },
                checkStrictly: true,
                slot: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
                  trigger: "click"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "deptId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "title"
                },
                checkStrictly: true,
                slot: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
                  trigger: "click"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.postId"),
                prop: "postId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "postName",
                  value: "id"
                },
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.postId"),
                  trigger: "click"
                }],
              },
            ]
          },
        ]
      },

    };
  },
  watch: {
    filterText(val) {
      this.$refs.commonTree.filter(val);
    },
    // 'form.tenantId'() {
    //   if (this.form.tenantId !== '' && this.initFlag) {
    //     this.initData(this.form.tenantId);
    //   }
    // },
    // 'excelForm.isCovered'() {
    //   if (this.excelForm.isCovered !== '') {
    //     const column = this.findObject(this.excelOption.column, "excelFile");
    //     column.action = `/api/sinoma-user/import-user?isCovered=${this.excelForm.isCovered}`;
    //   }
    // }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    // permissionList() {
    //   return {
    //     addBtn: this.vaildData(this.permission.user_add, false),
    //     viewBtn: this.vaildData(this.permission.user_view, false),
    //     delBtn: this.vaildData(this.permission.user_delete, false),
    //     editBtn: this.vaildData(this.permission.user_edit, false)
    //   };
    // },
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          prop: "roleId",
          span: 4,
          multiple: true,
          type: "tree",
          dicUrl: 'api/sinoma-system/role/treeByUserType?tenantId=' + this.userInfo.tenant_id,
          props: {
            label: "title"
          },
          checkStrictly: true,
          slot: true,
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
        },
        {
          label: "",
          prop: "postId",
          span: 4,
          type: "tree",
          multiple: true,
          dicUrl: '/api/sinoma-system/post/select?tenantId=' + website.tenantId + '',
          props: {
            label: "postName",
            value: "id"
          },
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.postId"),
        }
      ]
    }
  },
  created() {
    // this.getSysConfig()
  },
  mounted() {
    // // 非租户模式默认加载管理组数据
    // // if (!website.tenantMode) {
    // //   this.initData(website.tenantId);
    // // }
    if (this.deptCategory !== undefined || this.deptCategory) {
      this.getTreeData(this.deptCategory.join(','));
    } else {
      this.getTreeData();
    }

  },
  methods: {
    //请选择人员
    confirm() {
      this.$emit('select-all-data', {
        selectUserAllData: this.$refs.gridLayout.selectionList,
        selectDeptData: this.treeCheckData,
        treeDeptId: this.treeDeptId,
        treeDeptName: this.treeDeptName
      });
      this.closeDialog();
    },
    //清除人员
    closeDialog() {
      this.$refs.gridLayout.selectionList = [];
      this.$emit('close')
    },
    getTreeData(deptCategory) {
      getDeptTree("", "", this.userInfo.dept_id, this.treeParams).then(
        (res) => {
          this.treeData = res.data.data;
          this.$nextTick(() => {
            // this.defaultCheckedKeys.push(this.treeData[0].id);
            this.$refs.commonTree.setCurrentKey(this.treeData[0].id)
            this.treeDeptId = this.treeData[0].id;
            this.treeDeptName = this.treeData[0].title;
            this.onLoad({
              pageSize: 20,
              currentPage: 1
            });
          });
        }
      );
    },
    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.treeCheckData.push({
          orgId: data.id,
          orgName: data.title
        })
      } else {
        this.treeCheckData = this.treeCheckData.filter(val => val.orgId != data.id)
      }
      console.log(this.treeCheckData)
    },
    filterNode(value, data) {
      if (!value) return true;

      return data.title.indexOf(value) !== -1;
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.treeDeptName = data.title;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    searchReset(data) {
      this.query = {};
      if (this.userDeptType == "all") {
        this.treeDeptId = '';
      }
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == '') {
        delete params.account
      }
      if (params.realName == '') {
        delete params.realName
      }

      if (params.userTypeName == '') {
        delete params.userTypeName
      }
      delete params.$userTypeName
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);

    },
    selectionChange(list) {

      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    // currentChange(currentPage) {
    //   this.page.currentPage = currentPage;
    // },
    // sizeChange(pageSize) {
    //   this.page.pageSize = pageSize;
    // },
    // refreshChange() {
    //   this.onLoad(this.page, this.query);
    // },
    onLoad(page, params = {}) {
      this.param = params;
      this.page = page;
      this.tableLoading = true;
      this.query.isEnabled = 0;
      // if (this.userDeptType == "all") {
      //   this.query.deptName = "all";
      // }
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
        this.tableLoading = false;
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        if (this.echoMultipleData) {
          this.$nextTick(() => {
            this.tableData.forEach((item) => {  //给所有的data里面每一项设为false
              this.$refs.gridLayout.getGrid().toggleRowSelection(item, false);
              this.echoMultipleData.forEach(e => {
                if (item.id == e) {
                  this.$refs.gridLayout.getGrid().toggleRowSelection(item, true)
                }
              })
            });
          });
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree {
  // height: calc(100vh - 220px) !important;
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}

::v-deep .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}

::v-deep .el-dialog {
  margin-bottom: 0px !important;
}
</style>
