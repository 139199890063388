<template>
    <div>
        <el-container>
            <el-main>
                <head-layout
                    head-title="奖惩基础信息"
                    :head-btn-options="headTopBtnOptions"
                    @head-cancel="handleHeadGoback"
                    @head-save="headSave(1)"
                    @head-save-back="headSave(2)"
                />
                <form-layout
                    ref="formLayout"
                    :column="formColumn"
                    :dataForm.sync="dataForm"
                ></form-layout>
            </el-main>
        </el-container>
        <el-container>
            <el-main>
                <uploader
                    @getTableData="handleUpload"
                    :fileSize="500"
                    accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls"
                    :multiple="true"
                    :fileOptions="fileOptions"
                    ref="uploader"
                    :gridRowBtn="fileGridRowBtn"
                    :disabled="type == 'view'"
                ></uploader>
            </el-main>
        </el-container>
        <template v-if="type!=='add'">
            <el-container>
                <el-main>
                    <head-layout
                        head-title="接受对象"
                        :head-btn-options="userBtnOptions"
                        @head-add="handleUserAdd"
                    />
                    <grid-head-layout
                        ref="gridHeadLayout"
                        :search-columns="searchColumns"
                        @grid-head-search="gridHeadSearch"
                        @grid-head-empty="gridHeadEmpty"
                    ></grid-head-layout>
                    <grid-layout
                        ref="gridLayOut"
                        :grid-row-btn="gridRowBtn"
                        :table-options="tableOptions"
                        :table-data="tableData"
                        :table-loading="tableLoading"
                        :data-total="page.total"
                        :page="page"
                        :editType="inline"
                        @page-current-change="handleCurrentChange"
                        @page-size-change="handleSizeChange"
                        @page-refresh-change="onLoad"
                        @row-remove="rowUserRemove"
                    >
                    </grid-layout>
                </el-main>
            </el-container>
        </template>

        <el-dialog title="接受对象选择" :visible.sync="deptShow" width="80%" top="8vh">
            <UserDeptMultipleDialog ref="UserDeptMultipleDialog"  @select-all-data="selectAllData" @close="deptShow=false"></UserDeptMultipleDialog>
        </el-dialog>

    </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { decideheadSubmit, decideheadDetail, decideLinePage, decideLineSubmitBatch, decideLineRemove } from '@/api/responsibility/grsafetydecide'
import {mapGetters} from 'vuex'
import {downloadFileBlob} from "@/util/util";
import {dateFormat} from '@/util/date'
import UserDeptMultipleDialog from "./components/UserDeptMultipleDialog.vue"
import uploader from "@/components/file-upload/index";
import { deptChildTree } from "@/api/reportTemplate";
import ServerNameEnum from '@/util/ServerNameEnum';

export default {
    components: {
        FormLayout,
        HeadLayout,
        GridLayout,
        UserDeptMultipleDialog,
        uploader
    },

    data () {
        return {
            nodeData: {},
            type: '',
            dataForm: {},
            // fileData: [],
            fileLoading: false,
            tableLoading: false,
            tableData:[],
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            deptShow: false,
            fileOptions: {
                linklabel: 'name',
                column: [
                {
                    label: "附件名",
                    prop: "name",
                    align: "left",
                    overHidden: true,
                },
                {
                    label: "附件格式",
                    prop: "extension",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: "上传人",
                    prop: "createUserName",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: "上传时间",
                    prop: "createTime",
                    align: "center",
                    overHidden: true,
                },
                ],
            },
            fileGridRowBtn:[
                {
                    label: '下载',
                    emit: "row-download",
                },
                {
                    label: '删除',
                    emit: "row-del",
                },
            ]
        }
    },

    created () {
        this.nodeData = this.$route.query.row ? JSON.parse(this.$route.query.row) : {}
        this.type = this.$route.query.type
        if(this.nodeData.id)this.getDetail(this.nodeData.id)
        deptChildTree().then((res) => {
            this.formColumn[2].dicData = [res.data.data]
        });
    },

    mounted () {
        if(this.type == 'view'){
          this.$refs.formLayout.$refs.form.allDisabled = true
        }
    },

    computed: {
        ...mapGetters(["permission", 'colorName',"userInfo"]),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.eduquestions_add, true),
                viewBtn: this.vaildData(this.permission.eduquestions_view, true),
                editBtn: this.vaildData(this.permission.eduquestions_edit, true),
                delBtn: this.vaildData(this.permission.eduquestions_delete, true),
            };
        },
        headTopBtnOptions() {
            let buttonBtn = [];
            if (this.type != 'view') {
                buttonBtn.push(
                {
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-save",
                    type: "button",
                    icon: "",
                    btnOptType: 'save',
                    loading: this.saveLoading,
                });
                buttonBtn.push(
                {
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-save-back",
                    type: "button",
                    icon: "",
                    btnOptType: 'saveBack',
                    loading: this.saveLoading,
                });
            }
            buttonBtn.push(
                {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: "",
                });
            return buttonBtn;
        },

        formColumn() {
            return [
                {
                    label: "奖惩文书名称",
                    prop: "decideDocument",
                    labelWidth: 120,
                    placeholder: "请填写",
                    span:8,
                    rules: [{
                        required: true,
                        message: "请填写",
                        trigger: "blur"
                    }],
                    maxlength: 255,
                },
                {
                    label: "文书编号",
                    prop: "decideCode",
                    labelWidth: 120,
                    placeholder: "请填写",
                    span:8,
                    rules: [{
                        required: true,
                        message: "请填写",
                        trigger: "blur"
                    }],
                    maxlength: 255,
                },
                {
                    label: "颁发机构",
                    prop: "decideOrg",
                    labelWidth: 120,
                    placeholder: "请填写",
                    span:8,
                    type: 'tree',
                    dicData: [],
                    props: {
                    label: 'deptName',
                    value: 'id',
                    children: 'children'
                    },
                    lazy: false, // 禁用懒载，使下拉树立即加载所有节点
                    rules: [{
                        required: true,
                        message: "请填写",
                        trigger: "blur"
                    }]
                },
                {
                    label: "奖惩金额",
                    prop: "decideSum",
                    labelWidth: 120,
                    placeholder: "请填写",
                    span:8,
                    rules: [{
                        required: true,
                        message: "请填写",
                        trigger: "blur"
                    }],
                    maxlength: 50,
                },
                {
                    label: "奖惩时间",
                    prop: "decideDate",
                    labelWidth: 120,
                    placeholder: "请选择",
                    span:8,
                    type: 'date',
                    format: 'yyyy-MM-dd', // 设置日期格式
                    valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                    rules: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }]
                },
                {
                    label: "境内外",
                    prop: "prjDomestic",
                    labelWidth: 120,
                    placeholder: "请选择",
                    span:8,
                    type: 'select',
                    dicUrl: '/api/sinoma-system/dict/dictionary-tree?code=domestic_overseas',
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                    rules: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }]
                },
                {
                    label: "奖惩类型",
                    prop: "decideType",
                    labelWidth: 120,
                    placeholder: "请选择",
                    span:8,
                    type: 'select',
                    dicUrl: '/api/sinoma-system/dict-biz/dictionary-tree?code=decide_type',
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                    rules: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }]
                },
                {
                    label: "奖惩事项",
                    prop: "decideDesc",
                    labelWidth: 120,
                    placeholder: "请输入描述",
                    type: "textarea",
                    overHidden: true,
                    span: 24,
                    maxlength: 255,
                    showWordLimit: true,
                    rules: [{
                        required: true,
                        message: "请填写",
                        trigger: "change"
                    }]
                },
                {
                    label: "描述",
                    prop: "remark",
                    labelWidth: 120,
                    placeholder: "请输入描述",
                    sortable: true,
                    type: "textarea",
                    overHidden: true,
                    span: 24,
                    minRows: "2",
                    maxRows: "4",
                    maxlength: 255,
                    showWordLimit: true,
                },

            ]
        },

        userBtnOptions () {
            let buttonBtn = [];
            if(this.type!=='view'){
                buttonBtn.push({
                    label: "新增",
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
            }
            return buttonBtn;
        },

        searchColumns() {
            return [
                {
                    prop: "userName",
                    placeholder: "姓名",
                    span: 4,
                },
                {
                    prop: "orgName",
                    placeholder: "组织",
                    span: 4,
                },
            ]
        },

        tableOptions () {
            return{
                customRowAddBtn: false,
                menuWidth: 100,
                selection: false,
                index: true,
                indexLabel: "序号",
                column: [
                    {
                        label: "姓名",
                        prop: "userName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "组织",
                        prop: "orgName",
                        align: "center",
                    },
                ],
            }
        },
        gridRowBtn () {
            let buttonBtn = []
            if(this.type !== 'view'){
                buttonBtn.push({
                    label: "删除",
                    emit: "row-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'remove',
                })
            }
            return buttonBtn
        }

    },

    methods: {
        getDetail (id) {
            decideheadDetail({id}).then(res => {
                const data = res.data.data
                this.dataForm = data
                this.dataForm.prjDomestic = data.prjDomestic.toString()
                this.dataForm.decideType = data.decideType.toString()
                this.$refs.uploader.initTaleData(data.files)
                this.query.decideHeadId = data.id
                this.onLoad(this.page)
            })
        },

        handleHeadGoback () {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        headSave (val) {
            this.$refs.formLayout.$refs.form.validate(valid => {
                if(valid){
                    if(!this.dataForm.id)this.dataForm.belongOrgId = this.nodeData.belongOrgId
                    let regName = /^\d+(\.\d{1,2})?$/;
                    if(regName.test(this.dataForm.decideSum)){
                    //     // this.dataForm.files = JSON.stringify(this.fileData)
                        if(!this.dataForm.id)this.dataForm.belongOrgId = this.nodeData.belongOrgId
                      this.$loading();
                        decideheadSubmit({...this.dataForm}).then(res=>{
                            if(val==1 && !this.nodeData.id){
                                this.$router.$avueRouter.closeTag();
                                this.$router.replace({
                                    path: '/business/responsibility/grsafetydecide/edit',
                                    query: {
                                        row: JSON.stringify({id:res.data.data.id}),
                                        type: 'edit',
                                    }
                                });
                            }
                            if(val==2){
                                this.handleHeadGoback()
                            }
                          this.$loading().close();
                        })
                    } else {
                        this.$message.warning('请正确填写支出金额')
                    }
                    this.$refs.formLayout.$refs.form.allDisabled = false
                }
            })
        },

        handleUpload(data){
            this.dataForm.files = this.resetFileData(data)
        },
        //   组合附件数据
        resetFileData(data) {
            let arr = [];
            data.forEach((ele, ind) => {
                arr.push({
                createTime: ele.createTime,
                createUser: ele.createUser,
                createUserName: ele.createUserName,
                extension: ele.extension,
                fileSize: ele.fileSize,
                id: ele.id,
                path: ele.path,
                schedule: ele.schedule,
                uid: ele.uid,
                name: ele.name,
                size: ele.size,
                });
            });
            return JSON.stringify(arr);
        },

        handleUserAdd () {
            this.deptShow = true
        },

        getDate (data,format) {
            return dateFormat(data,format)
        },

        onLoad (page,params={}) {
            this.tableLoading = true;
            this.page = page;
            decideLinePage({
              current: page.currentPage,
              size: page.pageSize,
              ...params, ...this.query
            }).then(res => {
                const data = res.data.data;
                // this.$refs.gridLayOut.page.total = data.total;
                this.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                // this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },

        selectAllData (data) {
            const userList = data.selectUserAllData.map(val=>{
                return {
                    decideHeadId: this.nodeData.id,
                    orgId: data.treeDeptId,
                    userId: val.id,
                    userName: val.realName,
                    orgName: data.treeDeptName,
                }
            })
            const params = userList.concat(data.selectDeptData.map(val=>{
                return {
                    ...val,
                    decideHeadId: this.nodeData.id,
                }
            }))
            decideLineSubmitBatch(params).then(res=>{
                this.deptShow = false
                this.$message.success('操作成功')
                this.onLoad(this.page)
            })
        },

        rowUserRemove (row) {
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                decideLineRemove(row.id).then(() => {
                    this.onLoad(this.page);
                    this.$message.success('操作成功！')
                })
            });

        }

    }
}
</script>

<style lang="scss" scoped>
.file-name{
    color: #6F91C1;
    cursor: pointer;
    text-decoration: underline;
}
.headInfo{
    padding: 15px 12px 30px 12px;
    font-size: 14px;
    color: #3f4448;
    label{
        width: 100px;
    }
}

.file-table{
    width: 100%;
    text-align: center;
    border-color: #cccccc;
    font-size: 14px;
    color: #606266;
}
</style>
